export const getRouter = async () => {
  const ionRouter: HTMLIonRouterElement = await document.querySelector('ion-router').componentOnReady();
  return ionRouter;
};

export const getRedirectUrlToLogin = (): string => {
  let redirectUrl = '/login';
  if (window.location.pathname.startsWith('/events')) {
    redirectUrl += '?redirectUrl=' + encodeURIComponent(window.location.href);
  }

  return redirectUrl;
};
